<template>
  <v-sheet color="transparent">
    <Toolbar2 />
    <div class="container">
      <v-row>
        <v-col cols="12" md="8" class="mx-auto">
          <h1>Our Plans</h1>
        </v-col>
        <v-col
          md="4"
        >
          <div class="d-flex align-center justify-center">
            <span class="font-weight-semibold">Monthly</span>
            <v-switch
              v-model="status"
              value="annually"
              class="mx-3"
            ></v-switch>
            <span class="font-weight-semibold">Annually</span>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col v-for="plan in plans" :key="plan.id" cols="12" lg="4">
          <v-card
            class="text-center text-body-1 px-2 py-4"
          >
            <v-row>
              <v-col cols="12" lg="6">
                <div class="text-uppercase font-weight-black mb-6 text-h5">{{ plan.name }}</div>
              </v-col>
              <v-col cols="12" lg="6">
                <div class="d-flex justify-center m1-n3 primary--text">
                  <div style="margin-top: 2px; margin-right: 1px;">$</div>
                  <div class="text-h3">{{ status ? plan.yearlyPrice : plan.monthlyPrice }}</div>
                  <div style="margin-top: 2px; margin-right: 1px;">{{ status ? "/yr" : "/mo" }}</div>
                </div>
              </v-col>
            </v-row>

            <div class="mt-0 text-body-1">
              <div>
                <span class="font-weight-black mr-1">{{ plan.domains }}</span>
                <span class="font-weight-light">Your Brand Domains</span>
              </div>
              <div>
                <span class="font-weight-black mr-1">{{ plan.paths === -1 ? "Unlimited" : plan.paths }}</span>
                <span class="font-weight-light">Paths</span>
              </div>
              <div>
                <span class="font-weight-black mr-1"><v-icon size="14" color="green">{{ icons.mdiCheck }}</v-icon></span>
                <span class="font-weight-light">Path Tagging</span>
              </div>
              <div>
                <span class="font-weight-black mr-1"><v-icon size="14" color="green">{{ icons.mdiCheck }}</v-icon></span>
                <span class="font-weight-light">Generated or Custom Paths</span>
              </div>
              <div>
                <span class="font-weight-black mr-1"><v-icon size="14" color="green">{{ icons.mdiCheck }}</v-icon></span>
                <span class="font-weight-light">Root Path Redirection</span>
              </div>
              <div>
                <span class="font-weight-black mr-1"><v-icon size="14" color="green">{{ icons.mdiCheck }}</v-icon></span>
                <span class="font-weight-light">API Access</span>
              </div>
              <div>
                <span class="font-weight-black mr-1"><v-icon size="14" color="green">{{ icons.mdiCheck }}</v-icon></span>
                <span class="font-weight-light">Integrations Access</span>
              </div>
              <div v-if="plan.analytics === true">
                <span class="font-weight-black mr-1"><v-icon size="14" color="green">{{ icons.mdiCheck }}</v-icon></span>
                <span class="font-weight-light">Analytics (Browser, Location, Device, Date/Time)</span>
              </div>
              <div>
                <span class="font-weight-black mr-1"><v-icon size="14" color="green">{{ icons.mdiCheck }}</v-icon></span>
                <span class="font-weight-light">Branded Error Page</span>
              </div>
            </div>
            <v-btn
              :key="plan.id"
              x-large
              block
              class="mt-3 primary"
              href="https://dashboard.addysnip.com/register"
            >
              Get Started
            </v-btn>
          </v-card>
        </v-col>
        <v-col cols="12" lg="4">
          <v-card
            class="text-center text-body-1 px-2 py-4"
          >
            <v-row>
              <v-col cols="12" lg="12">
                <div class="text-uppercase font-weight-black mb-6 text-h5">Need Something Different?</div>
              </v-col>
            </v-row>

            <div class="mt-0 text-body-1">
              If you don't see what you need, please contact us for a custom quote.
            </div>
            <v-btn
              x-large
              block
              class="mt-3 primary"
              to="/contact"
            >
              Contact Us
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <Footer4Demo />
  </v-sheet>
</template>

<script>
import { mdiCheck } from '@mdi/js'
import Toolbar2 from '@/components/ui/landing/toolbar/Toolbar2.vue'
import Footer4Demo from '@/components/ui/landing/footer/Footer4Demo.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Plans',
  components: {
    Toolbar2,
    Footer4Demo
  },
  data() {
    return {
      status: false,
      icons: {
        mdiCheck
      }
    }
  },
  computed: {
    ...mapGetters({
      plansLoaded: 'plans/loaded',
      plansLoading: 'plans/loading',
      plans: 'plans/plans',
      subscribed: 'user/isSubscribed'
    })
  },
  mounted() {
    if (!this.plansLoaded) {
      this.$store.dispatch('plans/getPlans')
    }
    this.status = false
  }
}
</script>

<style></style>
